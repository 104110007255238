
<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="隐私协议" name="first">
                    <!-- <div class="plugins-tips"> 隐私协议 </div> -->
                    <el-form ref="form" v-model="privacy" label-width="40px" :rules="rules">
                        <el-form-item label="标题 " prop="applytitle">
                            <el-input v-model="privacy.applytitle"></el-input>
                        </el-form-item>
                        <el-form-item label="内容 " prop="applycontent">
                            <quill-editor ref="myTextEditor" v-model="privacy.applycontent" :options="editorOption"></quill-editor>
                        </el-form-item>
                    </el-form>
                    <el-button class="editor-btn" type="primary" @click="submitPrivacy">提交</el-button>
                </el-tab-pane>
                <el-tab-pane label="用户协议" name="second">
                    <el-form ref="form" v-model="user" label-width="40px" :rules="rules">
                        <el-form-item label="标题 " prop="applytitle">
                            <el-input v-model="user.applytitle"></el-input>
                        </el-form-item>
                        <el-form-item label="内容 " prop="applycontent">
                            <quill-editor ref="myTextEditor" v-model="user.applycontent" :options="editorOption"></quill-editor>
                        </el-form-item>
                    </el-form>
                    <el-button class="editor-btn" type="primary" @click="submitUser">提交</el-button>
                </el-tab-pane>
                <el-tab-pane label="用户帮助" name="third">
                    <el-form ref="form" v-model="userhelp" label-width="40px" :rules="rules">
                        <el-form-item label="标题 " prop="applytitle">
                            <el-input v-model="userhelp.applytitle"></el-input>
                        </el-form-item>
                        <el-form-item label="内容 " prop="applycontent">
                            <quill-editor ref="myTextEditor" v-model="userhelp.applycontent" :options="editorOption"></quill-editor>
                        </el-form-item>
                    </el-form>
                    <el-button class="editor-btn" type="primary" @click="submitUserhelp">提交</el-button>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import {
    request
} from '@/common/request';
export default {
    name: 'Privacyprotocol',
    components: {
        Breadcrumb,
        quillEditor
    },
    data() {
        return {
            loading: false,
            activeName: 'first',
            content: '',
            menuList: ['设置', '协议管理'],
            editorOption: {
                placeholder: 'Hello World'
            },
            user: {},
            privacy: {},
            userhelp: {},
            rules: {

            },
        }
    },
    created() {
        this.loadPrivacyData()
    },
    methods: {

        // 获取隐私协议数据
        loadPrivacyData() {
            this.loading = true;
            request.get('/system/agreement').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.privacy = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        // 获取用户协议数据
        loadUserData() {
            this.loading = true;
            request.get('/system/user/agreement').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.user = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 获用户私帮助数据
        loadUserhelpData() {
            this.loading = true;
            request.get('/system/help/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.userhelp = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        submitPrivacy() {
            //编辑隐私协议
            request.post('/system/agreement/edit', this.privacy).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('隐私协议编辑成功')
                    this.loadPrivacyData()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        submitUser() {
            //编辑用户协议
            request.post('/system/user/agreement/edit', this.user).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('用户协议编辑成功')

                    this.loadUserData()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        submitUserhelp() {
            //编辑用户帮助
            request.post('/system/help/edit', this.userhelp).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('用户帮助编辑成功')
                    this.loadUserhelpData()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        // tab切换
        handleClick(tab) {
            console.log(tab.paneName)
            if (tab.paneName === 'first') {
                this.loadPrivacyData()
            } else if (tab.paneName === 'second') {
                this.loadUserData()
            } else {
                this.loadUserhelpData()
            }
        },
    }
}
</script>
<style scoped>
</style>